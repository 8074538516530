<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Reading" name="reading">
        <div v-for="(item, index) in contents" :key="index">
          <div v-if="item.toefl_skill_id === 1">
            <h3>
              <b>ID: {{ item.id }}</b>
              <b class="text-success">
                {{ item.toefl_title }} - Reading - Passage {{ item.order }}
              </b>
            </h3>
            <div class="mb-4">
              {{ item.content }}
            </div>
            <div v-for="(c, indexL) in item.questions" :key="indexL">
              <b>ID:{{ c.id }} </b>
              <b class="text-success"> Question-{{ c.order }}: </b>
              {{ c.question }}
              <ul style="list-style-type:none;margin-top:10px">
                <li v-for="option in c.options" :key="option.id">
                  <b>ID:{{ option.id }} </b>
                  {{ option.title }}
                </li>
              </ul>
              <div><b>Exp: </b>{{ c.exp }}</div>
              <hr />
            </div>
            <hr style="border-top:3px solid rgba(0, 0, 0)" />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Listening" name="listening">
        <div v-for="(item, index) in contents" :key="index">
          <div v-if="item.toefl_skill_id === 2">
            <h3>
              <b>ID: {{ item.id }}</b>
              <b class="text-success">
                {{ item.toefl_title }} - Listening - Passage {{ item.order }}
              </b>
            </h3>
            <div class="mb-4">
              {{ item.content }}
            </div>
            <div v-for="(c, indexL) in item.questions" :key="indexL">
              <b>ID:{{ c.id }} </b>
              <b class="text-success"> Question-{{ c.order }}: </b>
              {{ c.question }}
              <ul style="list-style-type:none;margin-top:10px">
                <li v-for="option in c.options" :key="option.id">
                  <b>ID:{{ option.id }} </b>
                  {{ option.title }}
                </li>
              </ul>
              <div><b>Exp: </b>{{ c.exp }}</div>
              <hr />
            </div>
            <hr />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Speaking" name="speaking">
        <div v-for="(item, index) in contents" :key="index">
          <div v-if="item.toefl_skill_id === 3">
            <h3>
              <b>ID: {{ item.id }}</b>
              <b class="text-success">
                {{ item.toefl_title }} - Speaking - Passage {{ item.order }}
              </b>
            </h3>
            <div>
              <b>ID:{{ item.questions[0].id }} </b>
              <b class="text-success">Question: </b>
              {{ item.questions[0].question }}
            </div>
            <div><b>Exp: </b>{{ item.questions[0].exp }}</div>
            <hr />
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="Writing" name="fourth">定时任务补偿</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["contents"],
  data() {
    return {
      activeName: "reading"
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.hr {
  border-width: 5px;
  border-color: black;
}
</style>
